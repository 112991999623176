import React from "react"
import styled from "styled-components"

import { Cta, Media, TextGroup } from "components/common"

import { device, glassStyle } from "utils"

const CtaGetDemo = ({ title, subtitle, media, ctas }) => {
  return (
    <GetDemo>
      <div className="getDemoWrap">
        <TextGroup
          className="textGroup"
          title={title}
          subtitleAs="pSmall"
          subtitle={subtitle}
          cta={() => <Cta ctas={ctas} />}
        />
        <Media className="cta_image" media={media} />
      </div>
    </GetDemo>
  )
}

const GetDemo = styled.div`
  position: relative;
  padding: var(--section-mobile-padding-top) var(--section-mobile-padding)
    var(--section-mobile-padding-bottom);
  @media ${device.laptop} {
    padding: var(--section-padding-top) var(--section-mobile-padding)
      var(--section-padding-bottom);
  }

  .getDemoWrap {
    margin: 0 auto;
    max-width: 1080px;
    display: grid;
    grid-template-columns: 1fr;
    width: 100%;
    align-items: center;
    position: relative;
    z-index: 2;

    ${glassStyle};
    background: none;
    padding: var(--sp-24);
    padding-bottom: 0;

    @media ${device.laptop} {
      grid-gap: var(--sp-32);
      padding: var(--sp-80);
      padding-right: 0;
      grid-template-columns: 450px 1fr;
    }

    .textGroup {
      position: relative;
      z-index: 2;
      /* --tg-max-width: 400px; */

      .title {
        @media ${device.laptop} {
          white-space: pre;
        }
      }
      .subtitle {
        max-width: 400px;
      }
    }

    .cta_image {
      position: relative;
      left: calc(var(--section-mobile-padding) * -1);
      margin-right: calc(var(--section-mobile-padding) * -2);

      border-radius: 24px;

      img {
        border-radius: 24px;
        width: 100%;
      }

      @media ${device.laptop} {
        display: block;
        position: absolute;
        bottom: 0;
        right: 0;
        left: initial;
        margin-right: 0;
        z-index: 1;
        border-radius: 0;
      }
    }

    .cta {
      div,
      button {
        width: 100%;

        @media ${device.laptop} {
          width: initial;
        }
      }
    }
  }
`

export default CtaGetDemo
