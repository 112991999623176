import React from "react"
import styled from "styled-components"

import { useStaticQuery, graphql } from "gatsby"

import map from "lodash/map"
import { Card } from "components/common"
import { device, useContentfulEdges } from "utils"

const IntegrationGrid = () => {
  const { integrations } = useStaticQuery(query)
  const allIntegrations = useContentfulEdges(integrations)

  const renderGrid = () => (
    <ul>
      {map(allIntegrations, (integration) => (
        <Card
          key={integration.id}
          {...integration}
          title=""
          target="_blank"
          media={{ desktop: integration.logo }}
          to={`/integrations/${integration.slug}`}
          background
          ctas={[{ text: "Learn More", id: 1 }]}
        />
      ))}
    </ul>
  )

  return <StyledGrid>{renderGrid()}</StyledGrid>
}

const StyledGrid = styled.div`
  ul {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: var(--sp-16);

    @media ${device.laptop} {
      grid-template-columns: repeat(auto-fit, minmax(auto, 400px));
    }

    .cardImage {
      height: 60px;
      object-fit: contain !important;
    }
  }
`

const query = graphql`
  {
    integrations: allContentfulIntegration {
      edges {
        node {
          ...Integration
        }
      }
    }
  }
`

export default IntegrationGrid
