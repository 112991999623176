import React from "react"
import styled from "styled-components"

import { VerticalSection, Grid, FeatureCard } from "components/common"

const ProductFeatures = ({ title, subtitle, valueProps }) => {
  return (
    <StyledProductFeatures>
      <VerticalSection
        align="left"
        title={title}
        subtitle={subtitle}
        grid={() => (
          <Grid
            toEdge
            col={3}
            gap={24}
            // width="400px"
            list={valueProps}
            component={FeatureCard}
          />
        )}
      />
    </StyledProductFeatures>
  )
}

const StyledProductFeatures = styled.div`
  --tg-max-width: 790px;
  --section-max-width: 1200px;
  --section-background-color: none;
`

export default ProductFeatures
