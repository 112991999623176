import React from "react"
import styled from "styled-components"

import { VerticalSection, Cta } from "components/common"
import { device } from "utils"

const ProductHero = ({ title, subtitle, media, ctas }) => {
  return (
    <StyledProductHero>
      <VerticalSection
        align="center"
        title={title}
        titleAs="h1"
        subtitle={subtitle}
        media={media}
        cta={() => <Cta ctas={ctas} />}
      />
    </StyledProductHero>
  )
}

const StyledProductHero = styled.div`
  --section-padding-top: var(--sp-nav);
  --section-padding-right: 0;
  --section-padding-left: 0;

  --section-mobile-padding-top: var(--sp-mobile-nav);
  --section-mobile-padding-left: 0;
  --section-mobile-padding-right: 0;
  --tg-max-width: 713px;

  .tgWrap {
    padding: 0 var(--sp-24);

    @media ${device.laptop} {
      padding: 0;
    }
  }

  .imageWrap {
    margin-left: 0;
    margin-right: 0;
    @media ${device.laptop} {
      margin: 0;
    }

    .fileImage {
      width: 100%;
      max-width: 1200px;
    }

    img {
      width: 100%;
    }
  }
`

export default ProductHero
