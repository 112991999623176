import React from "react"
import styled from "styled-components"
import { graphql } from "gatsby"
import { Layout, Seo, Nav } from "components/common"
import { useContentfulSection, useMeta, glassStyle, device } from "utils"

import {
  ProductHero,
  ProductFeatures,
  ProductIntegrations,
} from "../sections/features/product"

import { CtaGetDemo } from "components/cta"

const ProductPage = ({ data: { product } }) => {
  const { product_hero, product_features, product_integrations, cta_get_demo } =
    useContentfulSection(product.sections)

  const meta = useMeta(product)

  return (
    <Layout>
      <Seo {...meta} />
      <Wrap>
        <Nav />
        <ProductHero {...product_hero} />
        <div className="glassBackground">
          <ProductFeatures {...product_features} />
          <ProductIntegrations {...product_integrations} />
        </div>

        <CtaGetDemo {...cta_get_demo} />
      </Wrap>
    </Layout>
  )
}

const Wrap = styled.div`
  .glassBackground {
    ${glassStyle};
    border-radius: 0;
    border: none;

    margin-bottom: var(--sp-80);
    @media ${device.laptop} {
      margin-bottom: var(--sp-section);
    }
  }
`

export const query = graphql`
  {
    product: contentfulPage(pageId: { eq: "product" }) {
      ...Page
    }
  }
`

export default ProductPage
