import React from "react"
import styled from "styled-components"

import { VerticalSection } from "components/common"
import { device } from "utils"

import IntegrationGrid from "src/sections/integrations/IntegrationGrid"

const ProductIntegrations = ({ title, subtitle, valueProps }) => {
  return (
    <StyledProductIntegrations>
      <VerticalSection
        align="left"
        title={title}
        subtitle={subtitle}
        custom={() => <IntegrationGrid />}
      />
    </StyledProductIntegrations>
  )
}

const StyledProductIntegrations = styled.div`
  --tg-max-width: 760px;
  --section-background-color: none;
  --section-max-width: 1200px;

  .customWrap {
    margin-top: var(--sp-32);
    @media ${device.laptop} {
      margin-top: var(--sp-64);
    }
  }
`

export default ProductIntegrations
